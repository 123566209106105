<template>
  <div class="container about-page">
    <div class="col-12">
      <h6 class="text-blue mb-3">{{ $t("taxReturn") }}</h6>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
          <p class="mb-3">
            {{ $t("aboutPage.aboutOneText") }}
          </p>
          <p>{{ $t("aboutPage.aboutTwoText") }}</p>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <img
            src="../../assets/img/about-bg.png"
            alt=""
            class="h-100 w-100"
            :style="{ borderRadius: '10px' }"
          />
        </div>
      </div>
      <h6 class="text-blue mb-3 mt-3">{{ $t("aboutPage.taxReturnMatter") }}</h6>
      <p class="mb-3">
        {{ $t("aboutPage.taxReturnMatterOneText") }}
      </p>
      <p class="mb-3">
        {{ $t("aboutPage.taxReturnMatterTwoText") }}
      </p>
      <router-link to="/registrieren">
        <button type="button" class="blue-button">
          {{ $t("aboutPage.taxButton") }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.about-page {
  padding: 160px 0px;
  text-align: justify;
}
</style>