<template>
  <div>
    <Navbar />
    <About />
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/shared/Footer.vue";
import Navbar from "../components/shared/Navbar.vue";
import About from "../components/information/About.vue";

export default {
  components: {
    Navbar,
    Footer,
    About,
  },
};
</script>

<style>
</style>